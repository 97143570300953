<template>
  <div>
    <div v-if="!loading" class="d-flex justify-content-between align-items-center mb-4">
      <h3>
        {{ $t('document.documentsOverview.unRecordedDocuments.title') }}
      </h3>
    </div>
    <TableLoadingSkeleton v-if="loading" />
    <Table
      v-if="!loading"
      :columns="columns"
      :data="data"
      custom-class="unrecorded-documents-table"
      :cell-class="handleCellClass"
      show-index
      border
      rounded
      hover
    >
      <template #cell-createdBefore="{ rowData: { createdBefore, createdBeforeWarning } }">
        <Tag :type="createdBeforeWarning ? 'warning' : 'neutral'" size="small">
          {{ createdBefore }}
        </Tag>
      </template>

      <template #cell-supplierName="{ rowData: { supplierName } }">
        {{ supplierName }}
      </template>

      <template #cell-source="{ rowData: { source } }">
        {{ source }}
      </template>

      <template #cell-uploadedBy="{ rowData: { uploadedBy } }">
        {{ uploadedBy }}
      </template>

      <template #cell-documentStatus="{ rowData: { documentStatus } }">
        {{ translateDocumentStatus(documentStatus) }}
      </template>

      <template #cell-documentInProcess="{ rowData: { rowIndex, documentInProcess, documentStatus, id, businessId } }">
        <div class="d-flex justify-content-between align-items-center">
          {{ translateDocumentInProcess(documentInProcess) }}
          <el-dropdown
            v-if="documentStatus === 'record' && documentInProcess === 'automation'"
            class="d-flex justify-content-center"
            trigger="click"
            placement="bottom"
            @command="handleAction(id, businessId)"
            @visible-change="(isVisible) => actionsVisibleChange(rowIndex, isVisible)"
          >
            <Button
              :id="`actions-row-${rowIndex}`"
              type="text"
              class="p-1 actions-btn text-typography-primary"
              :class="{ active: activeActions === rowIndex }"
            >
              <KebabIcon />
            </Button>
            <el-dropdown-menu>
              <el-dropdown-item :command="ACTIONS.SKIP_AUTOMATION">
                <div class="d-flex align-items-center gap-2">
                  {{ $t('document.documentsOverview.unRecordedDocuments.actions.skipAutomation') }}
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </template>
    </Table>
  </div>
</template>

<script>
import { computed, ref } from 'vue';

import { Table, Button, Tag, TableLoadingSkeleton } from '@/modules/core';
import { options } from '@/locale/dateConfig';
import { KebabIcon } from '@/assets/icons';

import { useDocuments } from '@/modules/document/compositions';
import { useTenancy } from '@/modules/auth';
import { useDocumentTasks, useCurrency } from '@/modules/document/compositions';
import { isNil } from 'ramda';
import { useCreateTask } from '@/modules/tasks';
import { DateTime } from 'luxon';

const TABLE_HEADERS = {
  CREATED_BEFORE: 'createdBefore',
  SUPPLIER_NAME: 'supplierName',
  SOURCE: 'source',
  UPLOADED_BY: 'uploadedBy',
  DOCUMENT_STATUS: 'documentStatus',
  DOCUMENT_IN_PROCESS: 'documentInProcess',
};

const DOCUMENT_STATUS = {
  organize: 'organize',
  upload: 'upload',
  record: 'record',
};

const DOCUMENT_IN_PROCESS = {
  organize: {
    manual: 'manual',
  },
  upload: {
    automation: 'automation',
    manual: 'manual',
  },
  record: {
    automation: 'automation',
    manual: 'manual',
  },
};

const ACTIONS = {
  SKIP_AUTOMATION: 'skipAutomation',
};

const TASK_TYPES = Object.freeze({
  ORGANIZE: 'organize',
  UPLOAD: 'upload',
  RECORD: 'record',
});

export default {
  components: { Table, Button, KebabIcon, Tag, TableLoadingSkeleton },
  setup() {
    const { createTask } = useCreateTask();
    const { currentTenantId } = useTenancy();
    const { currencyLocaleStyle } = useCurrency();

    const dateRange = ref();

    const {
      documents: unRecordedDocuments,
      totalCount: documentsCount,
      loading: unRecordedDocumentsLoading,
    } = useDocuments({ businessId: currentTenantId.value, sortBy: 'createdAt', orderBy: 'desc', recorded: false });

    const { documentTasks: organizeDocumentTasks, loading: organizeTasksLoading } = useDocumentTasks(() => ({
      businessId: currentTenantId.value,
      type: TASK_TYPES.ORGANIZE,
    }));

    const { documentTasks: uploadDocumentTasks, loading: uploadTasksLoading } = useDocumentTasks(() => ({
      businessId: currentTenantId.value,
      type: TASK_TYPES.UPLOAD,
    }));

    const {
      documentTasks: recordDocumentTasks,
      loading: recordTasksLoading,
      refetch: refetchRecordTasks,
    } = useDocumentTasks(() => ({
      businessId: currentTenantId.value,
      type: TASK_TYPES.RECORD,
    }));

    return {
      TASK_TYPES,
      ACTIONS,
      DOCUMENT_STATUS,
      DOCUMENT_IN_PROCESS,
      dateRange,
      documentsCount,
      createTask,
      unRecordedDocuments,
      organizeDocumentTasks,
      uploadDocumentTasks,
      recordDocumentTasks,
      refetchRecordTasks,
      loading: computed(
        () =>
          unRecordedDocumentsLoading.value ||
          organizeTasksLoading.value ||
          uploadTasksLoading.value ||
          recordTasksLoading.value
      ),
      currencyLocaleStyle,
    };
  },
  data() {
    return {
      activeActions: null,
      skeletonData: [{}, {}, {}, {}, {}],
      skeletonColumns: [
        {
          header: '',
          key: 'row1',
          width: '20px',
        },
        {
          header: '',
          key: 'row2',
          width: '80px',
        },
        {
          header: '',
          key: 'row3',
          width: '80px',
        },
        {
          header: '',
          key: 'row4',
          width: '80px',
        },
        {
          header: '',
          key: 'row5',
          width: '150px',
        },
      ],
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.$t('document.documentsOverview.unRecordedDocuments.header.created'),
          key: TABLE_HEADERS.CREATED_BEFORE,
          width: '128px',
        },
        {
          header: this.$t('document.documentsOverview.unRecordedDocuments.header.supplierName'),
          key: TABLE_HEADERS.SUPPLIER_NAME,
        },
        {
          header: this.$t('document.documentsOverview.unRecordedDocuments.header.source'),
          key: TABLE_HEADERS.SOURCE,
        },
        {
          header: this.$t('document.documentsOverview.unRecordedDocuments.header.uploadedBy'),
          key: TABLE_HEADERS.UPLOADED_BY,
        },
        {
          header: this.$t('document.documentsOverview.unRecordedDocuments.header.documentStatus'),
          key: TABLE_HEADERS.DOCUMENT_STATUS,
          width: '128px',
        },
        {
          header: this.$t('document.documentsOverview.unRecordedDocuments.header.documentInProcess'),
          key: TABLE_HEADERS.DOCUMENT_IN_PROCESS,
          width: '128px',
        },
      ];
    },
    data() {
      return this.unRecordedDocuments.map(({ id, businessId, createdAt, type, supplier, source }) => {
        const organizeTask = this.organizeDocumentTasks.find((task) => id === task.data.documentId);
        const uploadTask = this.uploadDocumentTasks.find((task) => id === task.data.documentId);
        const recordTask = this.recordDocumentTasks.find((task) => id === task.data.documentId);
        const { documentStatus, documentInProcess } = this.getDocumentStatus(
          supplier,
          type,
          organizeTask,
          uploadTask,
          recordTask
        );
        const { durationText, createdBeforeWarning } = this.formatDuration(createdAt);
        return {
          [TABLE_HEADERS.CREATED_BEFORE]: durationText,
          [TABLE_HEADERS.SUPPLIER_NAME]: supplier?.name ?? '-',
          [TABLE_HEADERS.SOURCE]: this.translateDocumentSource(source),
          [TABLE_HEADERS.DOCUMENT_STATUS]: documentStatus,
          [TABLE_HEADERS.DOCUMENT_IN_PROCESS]: documentInProcess,
          [TABLE_HEADERS.UPLOADED_BY]: this.getEmailAddress(uploadTask),
          id,
          businessId,
          createdBeforeWarning,
        };
      });
    },
  },
  methods: {
    handleCellClass(rowIndex) {
      if (this.activeActions === rowIndex) return 'bg-secondary';
    },
    actionsVisibleChange(index, isVisible) {
      this.activeActions = isVisible ? index : null;
    },
    async handleAction(documentId, businessId) {
      await this.refetchRecordTasks();

      const existRecordTask = this.recordDocumentTasks.find(
        ({ data: { documentId: existTaskDocumentId } }) => existTaskDocumentId === documentId
      );

      if (existRecordTask) return;

      const recordTask = {
        businessId: businessId,
        domain: 'document',
        type: TASK_TYPES.RECORD,
        data: {
          documentId: documentId,
        },
      };

      await this.createTask(recordTask);
      await this.refetchRecordTasks();
    },
    closeActions() {
      if (this.activeActions !== null) {
        document.getElementById(`actions-row-${this.activeActions}`).click();
        this.activeActions = null;
      }
    },
    getEmailAddress(uploadTask) {
      return !isNil(uploadTask) ? uploadTask.data.fromEmail : '';
    },
    getDocumentStatus(supplier, type, organizeTask, uploadTask, recordTask) {
      if (recordTask) {
        return {
          documentStatus: DOCUMENT_STATUS.record,
          documentInProcess: DOCUMENT_IN_PROCESS[DOCUMENT_STATUS.record].manual,
        };
      }

      if (uploadTask) {
        return {
          documentStatus: DOCUMENT_STATUS.upload,
          documentInProcess: DOCUMENT_IN_PROCESS[DOCUMENT_STATUS.upload].manual,
        };
      }

      if (organizeTask) {
        return {
          documentStatus: DOCUMENT_STATUS.organize,
          documentInProcess: DOCUMENT_IN_PROCESS[DOCUMENT_STATUS.organize].manual,
        };
      }

      if (isNil(supplier) && isNil(type)) {
        return {
          documentStatus: DOCUMENT_STATUS.upload,
          documentInProcess: DOCUMENT_IN_PROCESS[DOCUMENT_STATUS.upload].automation,
        };
      }

      if (supplier && type) {
        return {
          documentStatus: DOCUMENT_STATUS.record,
          documentInProcess: DOCUMENT_IN_PROCESS[DOCUMENT_STATUS.record].automation,
        };
      }

      return {
        documentStatus: null,
        documentInProcess: null,
      };
    },
    formatDate(ms) {
      return ms ? new Date(ms).toLocaleDateString(this.$i18n.locale, options.short) : null;
    },
    formatDuration(ms) {
      let createdBeforeWarning = false;
      const currentTime = new Date().getTime();
      const durationMillis = currentTime - ms;
      const durationSeconds = durationMillis / 1000;
      const durationHours = Math.floor(durationSeconds / 60 / 60);
      // less than a day
      if (durationHours >= 24) {
        createdBeforeWarning = true;
      }
      return {
        durationText: DateTime.fromMillis(ms).toRelative({ locale: this.$i18n.locale }),
        createdBeforeWarning,
      };
    },
    formatMoney(value) {
      return typeof value === 'number' && !Number.isNaN(value)
        ? Number(value).toLocaleString(this.$i18n.locale, { ...this.currencyLocaleStyle, maximumFractionDigits: 5 })
        : '-';
    },
    translateDocumentSource(source) {
      return source ? this.$t(`document.documentsOverview.unRecordedDocuments.documentSource.${source}`) : '-';
    },
    translateDocumentStatus(status) {
      return status ? this.$t(`document.documentsOverview.unRecordedDocuments.documentStatus.${status}`) : '';
    },
    translateDocumentInProcess(process) {
      return process ? this.$t(`document.documentsOverview.unRecordedDocuments.documentInProcess.${process}`) : '';
    },
    generateRandomNumber: () => {
      const min = 30;
      const max = 120;
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
.unrecorded-documents-table {
  .actions-btn {
    &:hover {
      background: $secondary;
    }
    &.active {
      visibility: visible;
    }
  }

  .hover-btn {
    &:hover {
      background: $secondary;
    }
  }

  tr {
    .actions-btn {
      visibility: hidden;
    }

    &:hover .actions-btn {
      visibility: visible;
    }
  }
}

.createdBeforeWarning {
  color: #c26900;
  background: #fef9f2;
  border-color: #fae3c1;
}
</style>
