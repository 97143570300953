<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h3>
        {{ $t('document.documentsOverview.unRecordedDocuments.title') }}
      </h3>
    </div>
    <div>
      <div class="d-flex align-items-center gap-2 mb-4 table-filters">
        <PopoverTableFilter
          ref="restPopoverSettings"
          width="240"
          trigger="click"
          :placement="$direction === 'rtl' ? 'bottom-end' : 'bottom-start'"
          :filter-name="$t('document.documentsOperationOverview.unRecordedDocumentsTable.filters.restaurant')"
          :filter-value="restaurantFilterName"
          :append-to-body="false"
          :clearable="true"
          @on-clear-filter="onRestaurantFilterChange(null)"
        >
          <ArrayWithSearchFilterPopover
            :arrayOptions="restaurants"
            @on-apply="onRestaurantFilterChange"
            optionWidth="180"
          />
        </PopoverTableFilter>
        <PopoverTableFilter
          ref="supPopoverSettings"
          width="240"
          trigger="click"
          :placement="$direction === 'rtl' ? 'bottom-end' : 'bottom-start'"
          popper-class="column-settings-popover"
          :append-to-body="false"
          :filter-name="$t('document.documentsOperationOverview.unRecordedDocumentsTable.filters.supplier')"
          :filter-value="supplierFilterName"
          :clearable="true"
          @on-clear-filter="onSupplierFilterChange(null)"
        >
          <ArrayWithSearchFilterPopover
            :arrayOptions="suppliers"
            @on-apply="onSupplierFilterChange"
            optionWidth="180"
          />
        </PopoverTableFilter>
      </div>
      <TableLoadingSkeleton v-if="documentsLoading" />
      <div v-else>
        <Table
          :columns="columns"
          :data="tableData"
          custom-class="unrecorded-documents-table"
          @row-click="handleRowClick"
          show-index
          border
          rounded
          hover
        >
          <template #cell-index="{ rowIndex }">
            {{ rowIndex + 1 + pageLimit * (currentPage - 1) }}
          </template>
          <template #cell-fileName="{ rowData: { fileName } }">
            <TruncatedText>
              {{ fileName }}
            </TruncatedText>
          </template>
          <template #cell-createdBefore="{ rowData: { createdBefore } }">
            <TruncatedText>
              {{ createdBefore }}
            </TruncatedText>
          </template>
          <template #cell-uploadedBy="{ rowData: { uploadedBy } }">
            <TruncatedText>
              {{ uploadedBy }}
            </TruncatedText>
          </template>
          <template #cell-businessName="{ rowData: { businessName } }">
            <TruncatedText>
              {{ businessName }}
            </TruncatedText>
          </template>
          <template #cell-supplierName="{ rowData: { supplierName } }">
            <TruncatedText>
              {{ supplierName }}
            </TruncatedText>
          </template>
          <template #cell-documentType="{ rowData: { documentType } }">
            <TruncatedText>
              {{ documentType }}
            </TruncatedText>
          </template>
          <template #cell-documentNumber="{ rowData: { documentNumber } }">
            <TruncatedText>
              {{ documentNumber }}
            </TruncatedText>
          </template>
          <template #cell-issueDate="{ rowData: { issueDate } }">
            <TruncatedText>
              {{ issueDate }}
            </TruncatedText>
          </template>
        </Table>
        <div class="d-flex justify-content-end my-4">
          <el-pagination
            v-if="documentsCount"
            small
            layout="prev, pager, next, jumper"
            background
            :current-page.sync="currentPage"
            :page-size="pageLimit"
            :page-count="Math.ceil(documentsCount / pageLimit)"
          />
        </div>
      </div>
    </div>
    <DocumentModal
      v-if="previewDocumentId"
      visible
      is-upload
      :document-id="previewDocumentId"
      @close="previewDocumentId = null"
    />
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { DateTime } from 'luxon';
import { Table, PopoverTableFilter, TruncatedText, TableLoadingSkeleton } from '@/modules/core';
import ArrayWithSearchFilterPopover from './ArrayWithSearchFilterPopover.vue';
import { DocumentModal } from '@/modules/documentModal';
import { useDocuments } from '@/modules/document/compositions';

const TABLE_HEADERS = {
  FILE_NAME: 'fileName',
  CREATED_BEFORE: 'createdBefore',
  UPLOADED_BY: 'uploadedBy',
  BUSINESS_NAME: 'businessName',
  SUPPLIER_NAME: 'supplierName',
  DOCUMENT_TYPE: 'documentType',
  DOCUMENT_NUMBER: 'documentNumber',
  ISSUE_DATE: 'issueDate',
};

export default {
  components: {
    Table,
    PopoverTableFilter,
    ArrayWithSearchFilterPopover,
    TruncatedText,
    DocumentModal,
    TableLoadingSkeleton,
  },
  setup() {
    const pageLimit = 50;
    const currentPage = ref(1);
    const previewDocumentId = ref(null);
    const restPopoverSettings = ref(null);
    const supPopoverSettings = ref(null);
    const selectedRestaurantId = ref(null);
    const selectedSupplierId = ref(null);

    const documentsFilterValues = computed(() => ({
      sortBy: 'createdAt',
      orderBy: 'desc',
      businessId: selectedRestaurantId.value?.optionId,
      pagination: {
        limit: pageLimit,
        offset: pageLimit * (currentPage.value - 1),
      },
      supplierId: selectedSupplierId.value?.optionId,
      recorded: false,
    }));
    const {
      documents: unRecordedDocuments,
      totalCount: documentsCount,
      loading: documentsLoading,
    } = useDocuments(documentsFilterValues);

    const closePopover = () => {
      if (restPopoverSettings.value) {
        restPopoverSettings.value.closePopover();
      }
      if (supPopoverSettings.value) {
        supPopoverSettings.value.closePopover();
      }
    };
    return {
      pageLimit,
      currentPage,
      previewDocumentId,
      restPopoverSettings,
      supPopoverSettings,
      selectedRestaurantId,
      selectedSupplierId,
      closePopover,
      unRecordedDocuments,
      documentsLoading,
      documentsCount,
    };
  },
  props: {
    restaurants: {
      type: Array,
      required: true,
    },
    suppliers: {
      type: Array,
      required: true,
    },
  },
  computed: {
    columns() {
      return Object.keys(TABLE_HEADERS).map((key) => {
        const columnWidth = [
          TABLE_HEADERS.FILE_NAME,
          TABLE_HEADERS.BUSINESS_NAME,
          TABLE_HEADERS.SUPPLIER_NAME,
        ].includes(TABLE_HEADERS[key])
          ? '200px'
          : '150px';
        return Object({
          header: this.$t(`document.documentsOperationOverview.unRecordedDocumentsTable.header.${TABLE_HEADERS[key]}`),
          key: TABLE_HEADERS[key],
          width: columnWidth,
        });
      });
    },
    tableData() {
      return this.filteredDocs.map((document) => {
        return {
          [TABLE_HEADERS.FILE_NAME]: document.filePath.split('/').pop(),
          [TABLE_HEADERS.CREATED_BEFORE]: this.formatDuration(document.createdAt),
          [TABLE_HEADERS.UPLOADED_BY]: this.getUploadedBy(document),
          [TABLE_HEADERS.BUSINESS_NAME]: document.business?.name ?? '-',
          [TABLE_HEADERS.SUPPLIER_NAME]: document.supplier?.name ?? '-',
          [TABLE_HEADERS.DOCUMENT_TYPE]: document.type ?? '-',
          [TABLE_HEADERS.DOCUMENT_NUMBER]: document.documentNumber ?? '-',
          [TABLE_HEADERS.ISSUE_DATE]: document.issueDate ?? '-',
          documentId: document.id,
        };
      });
    },

    filteredDocs() {
      let docs = this.unRecordedDocuments;
      if (this.selectedRestaurantId?.optionId) {
        docs = docs.filter((doc) => doc.businessId === this.selectedRestaurantId.optionId);
      }
      if (this.selectedSupplierId?.optionId) {
        docs = docs.filter((doc) => doc.supplierId === this.selectedSupplierId.optionId);
      }
      return docs;
    },
    restaurantFilterName() {
      return this.selectedRestaurantId?.optionId ? this.getRestaurantName(this.selectedRestaurantId?.optionId) : '';
    },
    supplierFilterName() {
      return this.selectedSupplierId?.optionId ? this.getSupplierName(this.selectedSupplierId?.optionId) : '';
    },
  },
  methods: {
    getUploadedBy(document) {
      return document.fromEmail ? document.fromEmail : this.getUserName(document.createdUser);
    },
    getUserName(user) {
      if (!user) return 'Unknown user';
      return `${user.firstName} ${user.lastName}`;
    },
    getRestaurantName(businessId) {
      const restaurant = this.restaurants?.find((r) => r.id === businessId);
      return restaurant?.name;
    },
    getSupplierName(supplierId) {
      const supplier = this.suppliers?.find((s) => s.id === supplierId);
      return supplier?.name;
    },
    formatDuration(ms) {
      return DateTime.fromMillis(ms).toRelative({ locale: this.$i18n.locale });
    },
    handleRowClick(rowIndex) {
      this.previewDocumentId = this.tableData[rowIndex].documentId;
    },
    onRestaurantFilterChange(restaurantId) {
      this.closePopover();
      this.selectedRestaurantId = restaurantId;
      this.currentPage = 1;
    },
    onSupplierFilterChange(supplierId) {
      this.closePopover();
      this.selectedSupplierId = supplierId;
      this.currentPage = 1;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
::v-deep .el-popover {
  margin-top: 0.5px !important;
}
</style>
